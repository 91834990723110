<template>
  <div>
    <searchable-table
      :items="requests"
      :fields="fields"
      hover
      @row-clicked="showRequest"
    >
      <template #fixed="data">
        <b-badge :variant="data.item.fixed ? 'success' : 'danger'">
          {{ data.item.fixed ? "Sabitlendi" : "Sabitlenmedi" }}
        </b-badge>
      </template>
      <template #address="data">
        {{ $capitalize(data.item.address.province.value) }},
        {{ $capitalize(data.item.address.county.value) }}
      </template>
      <template />
    </searchable-table>
  </div>
</template>
<script>
import webFormRequestSidebar from './sidebar/webFormRequestSidebar.vue'

export default {
  data() {
    return {
      fields: [
        { key: 'company.companyName', label: 'Firma', sortable: true },
        { key: 'address', label: 'Adres', sortable: true },
        {
          key: 'company.sector.value', label: 'Sektör', sortable: true, capitalize: true,
        },
        { key: 'company.riskState.value', label: 'Tehlike Sınıfı', sortable: true },
        { key: 'company.employeeCount', label: 'Çalışan Sayısı', sortable: true },
        {
          key: 'createdAt', label: 'Oluşturma Tarihi', sortable: true, formatDateTime: true,
        },
        {
          key: 'fixed', label: 'Durum', sortable: false, searchable: false,
        },
      ],
      webFormRequestSidebar,
    }
  },
  computed: {
    requests() {
      return this.$store.getters.getRequests
    },
  },
  mounted() {
    this.$store.dispatch('getData')
  },
  methods: {
    showRequest(item) {
      this.$showAppSidebar('Teklif Bilgileri', webFormRequestSidebar, item)
    },
  },
}
</script>
