<template>
  <app-detail-table
    :data="osgbCampaign"
    :fields="fields"
  />
</template>

<script>
export default {
  props: {
    osgbCampaign: {
      type: Object,
    },
  },
  data() {
    return {
      fields: [
        { key: 'osgb.name', label: 'OSGB Firması' },
        { key: 'monthlyPayment', label: 'Aylık Ödeme', addition: '₺' },
        { key: 'contractEndDate', label: 'Sözleşme Tarihi', formatDate: true },
      ],
    }
  },
}
</script>

<style></style>
