<template>
  <div v-if="request">
    <b-badge
      :variant="request.fixed ? 'success' : 'danger'"
      class="d-block p-50 mb-1"
    >
      {{ request.fixed ? "Sabitlendi" : "Sabitlenmedi" }}
    </b-badge>
    <app-collapse
      type="border"
      class="p-0"
    >
      <app-collapse-item
        v-for="item in items"
        v-show="request[item.key]"
        :key="item.key"
        :title="item.title"
        title-size="sm"
      >
        <component
          :is="item.component"
          :request="request"
          :[item.key]="request[item.key]"
        />
      </app-collapse-item>
    </app-collapse>
    <hr>
  </div>
</template>

<script>
import companyInfo from './companyInfo.vue'
import addressInfo from './addressInfo.vue'
import priceInfo from './priceInfo.vue'
import osgbCampaign from './osgbCampaign.vue'

export default {
  components: {
    companyInfo, addressInfo, priceInfo, osgbCampaign,
  },
  data() {
    return {
      items: [
        { key: 'company', component: 'companyInfo', title: 'Firma Bilgileri' },
        { key: 'address', component: 'addressInfo', title: 'Adres Bilgileri' },
        { key: 'osgbCampaign', component: 'osgbCampaign', title: 'Mevcut OSGB Bilgileri' },
        { key: 'offer', component: 'priceInfo', title: 'Fiyat Bilgileri' },
      ],
    }
  },
  computed: {
    request() {
      return this.$store.getters.appSidebarItem
    },
  },
}
</script>

<style></style>
