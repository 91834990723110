<template>
  <app-detail-table
    :data="request"
    :fields="fields"
  />
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    request: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields: [
        { key: 'company.userFullName', label: 'Firma Yetkilisi' },
        { key: 'phone', label: 'Telefon' },
        { key: 'company.email', label: 'E-Posta' },
        { key: 'company.companyName', label: 'Firma Unvanı' },
        { key: 'company.riskState.value', label: 'Tehlike Sınıfı' },
        { key: 'company.sector.value', label: 'Sektör', capitalize: true },
        { key: 'company.employeeCount', label: 'Çalışan Sayısı' },
        { key: 'company.areaMeter', label: 'Çalışma Alanı', addition: 'm²' },
        { key: 'osb.name', label: 'OSB/KSS' },
      ],
    }
  },
}
</script>

<style></style>
