<template>
  <app-detail-table
    :data="address"
    :fields="fields"
  />
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields: [
        { key: 'province.value', label: 'İl', capitalize: true },
        { key: 'county.value', label: 'İlçe', capitalize: true },
        { key: 'district.value', label: 'Mahalle', capitalize: true },
        { key: 'street', label: 'Sokak / Cadde' },
        { key: 'buildingNo', label: 'Numara' },
      ],
    }
  },
}
</script>

<style></style>
