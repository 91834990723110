<template>
  <div class="d-flex flex-column gap-15">
    <app-detail-table
      :data="offer"
      :fields="campaign"
    />
    <custom-collapse
      :collapses="discountsByPaymentType"
      title-size="sm"
    >
      <template
        v-for="(item, key) in discountsByPaymentType"
        :slot="item.key"
      >
        <app-detail-table
          :key="key"
          :data="offer.discountsByPaymentType[item.key]"
          :fields="prices"
        />
      </template>
    </custom-collapse>
  </div>
</template>

<script>
export default {
  props: {
    offer: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      discountsByPaymentType: [
        { key: 'normal', title: 'Aylık Taahhütsüz' },
        { key: 'monthlyCommitmentDiscountPercent', title: 'Aylık Taahhütlü' },
        { key: 'annualDownPaymentDiscount', title: 'Yıllık Peşin' },
      ],
      prices: [
        { key: 'totalDiscountAmount', label: 'İndirim Miktarı', addition: '₺' },
        { key: 'totalDiscountPercent', label: 'İndirim Yüzdesi', addition: '%' },
        { key: 'totalDiscountPrice', label: 'İndirimli Fiyat', addition: '₺' },
      ],
      campaign: [
        { key: 'basePrice', label: 'Temel Fiyat', addition: '₺' },
        { key: 'campaign.name', label: 'Kampanya' },
        { key: 'campaign.percent', label: 'İndirim Yüzdesi', addition: '%' },
      ],
    }
  },
}
</script>

<style></style>
